import { Global } from '@emotion/react';

function Fonts() {
  return (
    <Global
      styles={`
        /* Inter */
        @font-face {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url("/fonts/Inter-Regular.woff2") format("woff2");
        }
        @font-face {
          font-family: "Inter";
          font-style: italic;
          font-weight: 400;
          font-display: swap;
          src: url("/fonts/Inter-Italic.woff2") format("woff2");
        }
        @font-face {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url("/fonts/Inter-Medium.woff2") format("woff2");
        }
        @font-face {
          font-family: "Inter";
          font-style: italic;
          font-weight: 500;
          font-display: swap;
          src: url("/fonts/Inter-MediumItalic.woff2") format("woff2");
        }
        @font-face {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-display: swap;
          src: url("/fonts/Inter-SemiBold.woff2") format("woff2");
        }
        @font-face {
          font-family: "Inter";
          font-style: italic;
          font-weight: 600;
          font-display: swap;
          src: url("/fonts/Inter-SemiBoldItalic.woff2") format("woff2");
        }
        @font-face {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url("/fonts/Inter-Bold.woff2") format("woff2");
        }
        @font-face {
          font-family: "Inter";
          font-style: italic;
          font-weight: 700;
          font-display: swap;
          src: url("/fonts/Inter-BoldItalic.woff2") format("woff2");
        }
        /* Larken */
        @font-face {
          font-family: "Larken";
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url("/fonts/LarkenMedium.woff2") format("woff2");
        }
        @font-face {
          font-family: "Larken";
          font-style: italic;
          font-weight: 500;
          font-display: swap;
          src: url("/fonts/Larken-MediumItalic.woff2") format("woff2");
        }
      `}
    />
  );
}

export default Fonts;
