const env = process.env.NEXT_PUBLIC_VERCEL_ENV;
const id = process.env.NEXT_PUBLIC_GA_ID;
const isDeployed = (env !== 'development');

export const pageview = (url) => {
  if (window.gtag && isDeployed) {
    window.gtag('config', id, {
      page_path: url,
    });
  } else {
    console.log('GA Pageview debug', 'config', id, {
      page_path: url,
    });
  }
};

export const event = ({ action, params }) => {
  if (window.gtag && isDeployed) {
    window.gtag('event', action, params);
  } else {
    console.log('GA Event debug', 'event', action, params);
  }
};

export const setContentGroup = (contentGroupValue) => {
  if (window.gtag && isDeployed) {
    window.gtag('set', { content_group1: contentGroupValue });
  } else {
    console.log('GA Set debug', 'set', { content_group1: contentGroupValue });
  }
};
