import { extendTheme } from '@chakra-ui/react';

const colors = {
  black: '#000',
  white: '#fff',
  greyHover: '#9c9c9c',
  evergreen: {
    primary: '#264a42',
    background: '#eaedec',
    1: '#5e7872',
    2: '#a6b6b2',
    3: '#d3dad8',
    4: '#e8edec',
  },
  mint: '#d6e8db',
  // TODO replace and remove these
  alphaGray600: 'rgba(0, 0, 0, 0.6)',
  gray: {
    500: '#757575',
    600: '#666',
  },
};

const typography = {
  fonts: {
    heading: '"Larken", system-ui, sans-serif',
    body: '"Inter", system-ui, sans-serif',
  },

  // TODO remove these and pass textStyles props to components instead
  lineHeights: {
    short: 1.3,
  },

  // TODO remove these and pass textStyles props to components instead
  fontSizes: {
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.333rem', // 21.33px
    xl: '1.777rem', // 28.43px
    '2xl': '2.369rem', // 37.90px
    '3xl': '3.157rem', // 50.52px
    '4xl': '4.209rem', // 67.34px
    '5xl': '5.61rem', // 89.76px
  },

  // TODO come up with better names for these
  textStyles: {
    h1: {
      fontFamily: 'Larken',
      fontSize: 'clamp(2.94rem, 4.2vi + 1.36rem, 5.56rem)', // 46px (@600px) – 89px (@1600px)
      lineHeight: '1',
      fontWeight: '500',
      letterSpacing: '-0.0056em', // 1 / (89px / -0.5px)
    },
    h2: {
      fontFamily: 'Larken',
      fontSize: 'clamp(2.33rem, 1.78vi + 1.66rem, 3.44rem)', // 37.2px (@600px) - 55px (@1600px)
      lineHeight: '1',
      fontWeight: '500',
      letterSpacing: '-0.015625rem', // -0.25px
    },
    h3: {
      fontFamily: 'Larken',
      fontSize: 'clamp(1.38rem, 1.2vi + 0.93rem, 2.13rem)', // 22px(@600px) – 34px (@1600px)
      lineHeight: 'clamp(1.5rem, 1.4vi + 0.97rem, 2.38rem)', // 24px(@600px) – 38px (@1600px)
      fontWeight: '500',
    },
    h4: {
      fontFamily: 'Inter',
      fontSize: 'clamp(1.3rem, 0.47vi + 1.12rem, 1.59rem)', // 26px (@600px) - 34px (@1600px)
      lineHeight: 'clamp(1.5rem, 0.6vi + 1.27rem, 1.88rem)', // 30px (@600px) - 40px (@1600px)
      fontWeight: '400',
      letterSpacing: '-0.0625rem', // -1px
    },
    h5: {
      fontFamily: 'Inter',
      fontSize: '1.3125rem', // 21px
      lineHeight: '1.75rem', // 28px
      fontWeight: '500',
      letterSpacing: '-0.015625rem', // -0.25px
    },
    h6: {
      fontFamily: 'Inter',
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      fontWeight: '700',
    },
    h7: {
      fontFamily: 'Inter',
      fontSize: '0.8125rem', // 13px
      lineHeight: '1.25rem', // 20px
      fontWeight: '500',
      letterSpacing: '0.03125rem', // 0.5px
      textTransform: 'uppercase',
    },
    h8: {
      fontFamily: 'Inter',
      fontSize: 'clamp(1.06rem, 0.4vi + 0.91rem, 1.31rem)', // 17px (@600px) - 21px (@1600px)
      lineHeight: 'clamp(1.38rem, 0.6vi + 1.15rem, 1.75rem)', // 22px (@600px) – 28px (@1600px)
      fontWeight: '500',
      letterSpacing: '-0.015625rem', // -0.25px
    },
    body: {
      fontFamily: 'Inter',
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      fontWeight: '500',
    },
    link: {
      fontFamily: 'Inter',
      fontSize: '0.8125rem', // 13px
      lineHeight: '1.25rem', // 20px
      fontWeight: '700',
      letterSpacing: '0.03125rem', // 0.5px
      textTransform: 'uppercase',
    },
  },
};

const customTheme = {
  styles: {
    global: () => ({
      body: {
        bg: 'black',
        color: 'white',
        textStyle: 'body',
      },
    }),
  },
  colors,
  ...typography,
  shadows: {
    outline: '0 0 0 3px #264a42, 0 0 2px 6px #d3dad8',
    // Some elements already use a white box-shadow for border
    // include that in their focus state
    outlineCard: '0 0 0 1px #fff, 0 0 0 3px #264a42, 0 0 2px 6px #d3dad8',
    outlineActiveThumb: '0 0 0 4px #fff, 0 0 2px 6px #d3dad8',
    outlineWhite: '0 0 0 4px #fff',
  },
  space: {
    sideSpace: 'clamp(0.938rem, 0.6512rem + 1.2238vw, 1.875rem)',
  }
};

const theme = extendTheme(customTheme);
export default theme;
