import Script from 'next/script';
import PropTypes from 'prop-types';

function GoogleAnalytics({ id, consentGiven, onLoad }) {
  return consentGiven && (
    <>
      <Script
        key="gtag"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
        onLoad={onLoad}
      />
      <Script
        key="ga-script"
        id="ga-script"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${id}', {
      page_path: window.location.pathname,
      send_page_view: false,
    });
  `,
        }}
      />
      <Script
        key="gtm-script"
        id="gtm-script"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5ZF94K6');`
        }} />
      <noscript
      // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5ZF94K6"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        }}
      />
    </>
  )
}

GoogleAnalytics.propTypes = {
  id: PropTypes.string.isRequired,
  consentGiven: PropTypes.bool,
  onLoad: PropTypes.func.isRequired,
};

GoogleAnalytics.defaultProps = {
  consentGiven: false,
};

export default GoogleAnalytics;
