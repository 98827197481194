import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';

const buttonStyle = {
  fontWeight: 600,
  color: '#fff',
  backgroundColor: '#000',
  border: '1px solid #fff',
  borderRadius: 0,
};

function CookieBanner({ setCookieConsent }) {
  const { t } = useTranslation('common');

  return (
    <CookieConsent
      location="bottom"
      buttonText={t('accept')}
      ariaAcceptLabel={t('accept')}
      declineButtonText={t('decline')}
      ariaDeclineLabel={t('decline')}
      cookieName="snmCookieConsentGiven"
      style={{ background: '#000', borderTop: '1px solid #fff' }}
      buttonStyle={buttonStyle}
      declineButtonStyle={buttonStyle}
      expires={365}
      enableDeclineButton
      flipButtons
      onAccept={() => {
        setCookieConsent(true);
      }}
      onDecline={() => {
        setCookieConsent(false);
      }}
    >
      <Text as="h2" textStyle="h3">{t('cookie-consent-heading')}</Text>
      <Text mt={3}>{t('cookie-consent-message')}</Text>
      <Text>Google (Analytics) <code>_ga</code>, <code>_gid</code>, <code>_gat</code></Text>
    </CookieConsent>
  );
}

CookieBanner.propTypes = {
  setCookieConsent: PropTypes.func.isRequired,
};

export default CookieBanner;
