/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
import '../styles/globals.css';
import 'maplibre-gl/dist/maplibre-gl.css';

import ProgressBar from '@badrap/bar-of-progress';
import { ChakraProvider } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';

import CookieBanner from '../components/CookieBanner/CookieBanner';
import GoogleAnalytics from '../components/GoogleAnalytics';
import { pageview } from '../lib/analytics';
import { GA_ID } from '../lib/constants/global';
import Fonts from '../lib/Fonts'
import theme from '../lib/theme';

const progress = new ProgressBar({ color: '#5e7872', size: 5 });

function App({ Component, pageProps }) {
  const router = useRouter();
  const isBrowser = typeof window !== 'undefined';
  const [isGtagLoaded, setIsGtagLoaded] = useState();
  const [cookieConsentGiven, setCookieConsentGiven] = useState(false);

  // First time get the value from the cookie
  useEffect(() => {
    const { snmCookieConsentGiven = 'false' } = parseCookies();
    setCookieConsentGiven(snmCookieConsentGiven === 'true');
  }, []);

  // Ensure pageview collected on initial load.
  const [initialRouteTracked, setInitialRouteTracked] = useState(false);

  useEffect(() => {
    if (isBrowser && cookieConsentGiven && isGtagLoaded && !initialRouteTracked) {
      pageview(window.location.pathname + window.location.search);
      setInitialRouteTracked(true);
    }
  }, [cookieConsentGiven, isGtagLoaded]);

  // Collect other pageviews using nextjs router events.
  useEffect(() => {
    // Progress bar events.
    router.events.on('routeChangeStart', () => progress.start());
    router.events.on('routeChangeComplete', () => progress.finish());
    router.events.on('routeChangeError', () => progress.finish());

    // Page view analytics event.
    const handleRouteChange = (url) => {
      pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ChakraProvider theme={theme} resetCSS>
      <Fonts />
      <CookieBanner setCookieConsent={setCookieConsentGiven} />
      <Component {...pageProps} />
      <GoogleAnalytics
        id={GA_ID}
        consentGiven={cookieConsentGiven}
        onLoad={() => setIsGtagLoaded(true)}
      />
    </ChakraProvider>
  );
}

export default appWithTranslation(App);
